import * as React from "react"
import Helmet from "react-helmet"
import { Box } from "@chakra-ui/react"

// markup
const PrivacyPolicy = () => {
    return (
        <Box className="TCContainer" maxWidth="1269px" >
            <Helmet>
                <title>Privacy Policy | Careway</title>
            </Helmet>
            <h1>Privacy Policy</h1>
            <p>Privacy<br />
            The Privacy Act 1993 and Health Information Privacy Code 1994 regulates how we can collect, use, hold, disclose, access, correct, manage and dispose of your personal information.
When you browse our website, you may do so without providing any personal information. However, where you may voluntarily provide your personal information, (e.g. via email requests or online forms) we are required to manage your information safely and with respect as per the Privacy Act 1993 and Health Information Privacy Code 1994. </p>


            <p><b>Use of the Google web analytics tool</b><br />
            www.careway.co.nz uses Google Analytics to collect visitor data and analyse traffic on our site. This information helps us understand customer interests and helps us improve our website. When you visit our site, the pages that you look at, and a short text file called a cookie, are downloaded to your computer. A cookie is used to store small amounts of information. This information is collected for traffic analysis only. The cookie does not contain personal details. Depending on the browser that you use, you can set your preferences to block/ refuse cookies, and/ or notify you before they are placed. Google analytics does not sell, give, or trade the statistics they store to any 3rd parties for data-mining or marketing purposes. Please visit http://www.google.com/intl/en/policies/privacy/ for their privacy policy.</p>


            <p><b>Use of external links</b><br />
            www.careway.co.nz may contain links to third-party websites operated by providers that are not associated with us. After you click the link, we no longer have any influence over the collection, storage, or processing of any personal data transmitted by clicking the link (such as the IP address or URL of the page that contains the link), as the behaviour of third parties is, by nature, beyond our control. Therefore, Careway is not responsible for the processing of personal data by third parties</p>
        </Box>
    )
}

export default PrivacyPolicy
